// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(status) {
  return Json_Encode$JsonCombinators.object([[
                "status",
                status
              ]]);
}

var Req = {
  toJson: toJson
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              message: field.required("message", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var Res = {
  decoder: decoder,
  fromJson: fromJson
};

function exec(id, status) {
  return Rest.$$fetch("/dashboard/suspicious_emails/" + ID.toString(id), {
              NAME: "Patch",
              VAL: toJson(status)
            }, {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
