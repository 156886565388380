// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H1 from "../../../../styleguide/components/Heading/H1.res.js";
import * as H2 from "../../../../styleguide/components/Heading/H2.res.js";
import * as H3 from "../../../../styleguide/components/Heading/H3.res.js";
import * as Table from "../../../../styleguide/components/Table/Table.res.js";
import * as TableRow from "../../../../styleguide/components/Table/TableRow.res.js";
import * as TableBody from "../../../../styleguide/components/Table/TableBody.res.js";
import * as TableCell from "../../../../styleguide/components/Table/TableCell.res.js";
import * as TableHeader from "../../../../styleguide/components/Table/TableHeader.res.js";
import * as TableHeaderCell from "../../../../styleguide/components/Table/TableHeaderCell.res.js";
import Format from "date-fns/format";
import * as JsxRuntime from "react/jsx-runtime";
import * as SuspiciousEmailEditForm from "./form/SuspiciousEmailEditForm.res.js";
import * as SuspiciousEmailEditScss from "./SuspiciousEmailEdit.scss";
import * as DashboardSuspiciousEmailNavbar from "../common/navbar/DashboardSuspiciousEmailNavbar.res.js";

var css = SuspiciousEmailEditScss;

function formatDate(date) {
  return Format(new Date(date), "MMMM dd, yyyy, h:mm aaaa");
}

function fromOptionalDate(date, message) {
  if (date === null || date === undefined) {
    return message;
  } else {
    return Format(new Date(date), "MMMM dd, yyyy, h:mm aaaa");
  }
}

function SuspiciousEmailEdit$default(props) {
  var suspiciousEmail = props.suspiciousEmail;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(DashboardSuspiciousEmailNavbar.make, {
                      currentLink: "Edit",
                      suspiciousEmail: suspiciousEmail
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(H1.make, {
                                      className: css.title,
                                      children: "Edit Suspicious Email"
                                    }),
                                JsxRuntime.jsx(H2.make, {
                                      className: Cx.cx([
                                            css.subTitle,
                                            "text-2xl"
                                          ]),
                                      children: suspiciousEmail.email
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Created At: " + Format(new Date(suspiciousEmail.createdAt), "MMMM dd, yyyy, h:mm aaaa"),
                                              className: css.createdAt
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: suspiciousEmail.isValid ? JsxRuntime.jsx("span", {
                                                      children: "Is Valid",
                                                      className: css.valid
                                                    }) : JsxRuntime.jsx("span", {
                                                      children: "Not Valid",
                                                      className: css.invalid
                                                    })
                                            })
                                      ],
                                      className: css.data
                                    })
                              ],
                              className: css.titleContainer
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(H3.make, {
                                              className: css.sectionTitle,
                                              children: "Edit Status"
                                            }),
                                        JsxRuntime.jsx(SuspiciousEmailEditForm.make, {
                                              suspiciousEmail: suspiciousEmail
                                            })
                                      ],
                                      className: css.section
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(H3.make, {
                                              className: css.sectionTitle,
                                              children: "History"
                                            }),
                                        JsxRuntime.jsxs(Table.make, {
                                              children: [
                                                JsxRuntime.jsx(TableHeader.make, {
                                                      children: JsxRuntime.jsxs(TableRow.make, {
                                                            children: [
                                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                                    children: "Column"
                                                                  }),
                                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                                    children: "Value"
                                                                  })
                                                            ]
                                                          })
                                                    }),
                                                JsxRuntime.jsxs(TableBody.make, {
                                                      children: [
                                                        JsxRuntime.jsxs(TableRow.make, {
                                                              children: [
                                                                JsxRuntime.jsx(TableCell.make, {
                                                                      children: "Date Blocked"
                                                                    }),
                                                                JsxRuntime.jsx(TableCell.make, {
                                                                      children: fromOptionalDate(suspiciousEmail.blockedAt, "Not Set")
                                                                    })
                                                              ]
                                                            }),
                                                        JsxRuntime.jsxs(TableRow.make, {
                                                              children: [
                                                                JsxRuntime.jsx(TableCell.make, {
                                                                      children: "Timeout Reset"
                                                                    }),
                                                                JsxRuntime.jsx(TableCell.make, {
                                                                      children: fromOptionalDate(suspiciousEmail.timeoutAt, "Not Set")
                                                                    })
                                                              ]
                                                            }),
                                                        JsxRuntime.jsxs(TableRow.make, {
                                                              children: [
                                                                JsxRuntime.jsx(TableCell.make, {
                                                                      children: "Invalid Attempts"
                                                                    }),
                                                                JsxRuntime.jsx(TableCell.make, {
                                                                      children: String(suspiciousEmail.invalidAttempts)
                                                                    })
                                                              ]
                                                            })
                                                      ]
                                                    })
                                              ]
                                            })
                                      ],
                                      className: css.section
                                    })
                              ],
                              className: css.bodyContainer
                            })
                      ],
                      className: css.container
                    })
              ],
              className: css.pageContainer
            });
}

var $$default = SuspiciousEmailEdit$default;

export {
  css ,
  formatDate ,
  fromOptionalDate ,
  $$default as default,
}
/* css Not a pure module */
