// extracted by mini-css-extract-plugin
export var buttonContainer = "SuspiciousEmailEditForm__buttonContainer__Gtp4k";
export var column = "SuspiciousEmailEditForm__column__MNp71";
export var flex = "SuspiciousEmailEditForm__flex__ey05L";
export var flexColumn = "SuspiciousEmailEditForm__flexColumn__QuTjP";
export var form = "SuspiciousEmailEditForm__form__BJWI6";
export var gap1 = "SuspiciousEmailEditForm__gap1__hBKz3";
export var gap2 = "SuspiciousEmailEditForm__gap2__syw8E";
export var gap3 = "SuspiciousEmailEditForm__gap3__W5Nnu";
export var gap4 = "SuspiciousEmailEditForm__gap4__ou548";
export var gap5 = "SuspiciousEmailEditForm__gap5__Gmja8";
export var row = "SuspiciousEmailEditForm__row__Pz5Ni";