// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Link from "../../../../../styleguide/components/Link/Link.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_SuspiciousEmail from "../../../../../routes/common/Routes_SuspiciousEmail.res.js";
import * as DashboardSuspiciousEmailNavbarScss from "./DashboardSuspiciousEmailNavbar.scss";

var css = DashboardSuspiciousEmailNavbarScss;

function DashboardSuspiciousEmailNavbar(props) {
  var suspiciousEmail = props.suspiciousEmail;
  var __currentLink = props.currentLink;
  var currentLink = __currentLink !== undefined ? __currentLink : "Index";
  var linkCss = function (link) {
    if (link === currentLink) {
      return Cx.cx([
                  css.linkContainer,
                  css.underlinedLink
                ]);
    } else {
      return css.linkContainer;
    }
  };
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(Link.make, {
                                  href: Routes_SuspiciousEmail.Dashboard.index,
                                  className: css.linkItem,
                                  children: "Suspicious Emails"
                                }),
                            className: linkCss("Index")
                          }),
                      suspiciousEmail !== undefined ? JsxRuntime.jsx(JsxRuntime.Fragment, {
                              children: Caml_option.some(JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(Link.make, {
                                              href: Routes_SuspiciousEmail.Dashboard.edit(suspiciousEmail.id),
                                              className: css.linkItem,
                                              children: "Edit Suspicious Email"
                                            }),
                                        className: linkCss("Edit")
                                      }))
                            }) : null
                    ],
                    className: css.barContainer
                  }),
              className: css.barWrapper
            });
}

var make = DashboardSuspiciousEmailNavbar;

export {
  css ,
  make ,
}
/* css Not a pure module */
