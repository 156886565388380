// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Url from "../../../../../utils/Url.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as Select from "../../../../../styleguide/forms/Select/Select.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ErrorMessage from "../../../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as SuccessMessage from "../../../../../styleguide/forms/SuccessMessage/SuccessMessage.res.js";
import * as SuspiciousEmail from "../../../../../models/SuspiciousEmail.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Api_SuspiciousEmail from "../../../../../api/suspicious-emails/Api_SuspiciousEmail.res.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";
import * as Routes_SuspiciousEmail from "../../../../../routes/common/Routes_SuspiciousEmail.res.js";
import * as SuspiciousEmailEditFormScss from "./SuspiciousEmailEditForm.scss";

var css = SuspiciousEmailEditFormScss;

var initialState = {
  message: undefined
};

function reducer(param, action) {
  return {
          message: action._0
        };
}

var validators = {
  status: {
    strategy: "OnFirstSuccessOrFirstBlur",
    validate: (function (input) {
        var x = input.status;
        if (x.trim() === "") {
          return {
                  TAG: "Error",
                  _0: "Status is required"
                };
        } else {
          return {
                  TAG: "Ok",
                  _0: input.status
                };
        }
      })
  }
};

function initialFieldsStatuses(_input) {
  return {
          status: "Pristine"
        };
}

function initialState$1(input) {
  return {
          input: input,
          fieldsStatuses: {
            status: "Pristine"
          },
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.status;
  var match$1;
  match$1 = typeof match !== "object" ? validators.status.validate(input) : match._0;
  if (match$1.TAG === "Ok") {
    return {
            TAG: "Valid",
            output: {
              status: match$1._0
            },
            fieldsStatuses: {
              status: {
                TAG: "Dirty",
                _0: match$1,
                _1: "Shown"
              }
            },
            collectionsStatuses: undefined
          };
  } else {
    return {
            TAG: "Invalid",
            fieldsStatuses: {
              status: {
                TAG: "Dirty",
                _0: match$1,
                _1: "Shown"
              }
            },
            collectionsStatuses: undefined
          };
  }
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState$1(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurStatusField" :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.status, validators.status, (function (status) {
                          return {
                                  status: status
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState$1(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateStatusField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.status, state.submissionStatus, validators.status, (function (status) {
                                    return {
                                            status: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                status: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                status: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateStatus: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateStatusField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurStatus: (function () {
              dispatch("BlurStatusField");
            }),
          statusResult: Formality.exposeFieldResult(state.fieldsStatuses.status),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.status;
              if (typeof tmp !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var SuspiciousEmailForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState$1,
  validateForm: validateForm,
  useForm: useForm
};

function SuspiciousEmailEditForm(props) {
  var suspiciousEmail = props.suspiciousEmail;
  var initialInput = React.useMemo((function () {
          return {
                  status: suspiciousEmail.status
                };
        }), []);
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var form = useForm(initialInput, (function (output, cb) {
          $$Promise.wait(Api_SuspiciousEmail.update(suspiciousEmail.id, output.status), (function (x) {
                  if (x.TAG !== "Ok") {
                    return SentryLogger.error1({
                                rootModule: "SuspiciousEmailEditForm",
                                subModulePath: /* [] */0,
                                value: "make",
                                fullPath: "SuspiciousEmailEditForm.make"
                              }, "SuspiciousEmailEdit::Error", [
                                "Error",
                                cb.notifyOnFailure()
                              ]);
                  }
                  dispatch({
                        TAG: "SetMessage",
                        _0: x._0.message
                      });
                  cb.notifyOnSuccess(undefined);
                }));
        }));
  var fieldError = function (field) {
    if (field !== undefined && field.TAG !== "Ok") {
      return JsxRuntime.jsx(ErrorMessage.make, {
                  className: css.error,
                  children: field._0
                });
    } else {
      return null;
    }
  };
  var match$1 = form.status;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "SubmissionFailed" ? null : JsxRuntime.jsx(ErrorMessage.make, {
          className: css.error,
          children: "Something went wrong."
        });
  var x = match[0].message;
  return JsxRuntime.jsxs("form", {
              children: [
                tmp,
                x !== undefined ? JsxRuntime.jsx(SuccessMessage.make, {
                        children: x
                      }) : null,
                JsxRuntime.jsxs(Select.make, {
                      id: "suspicious-email-form--status",
                      value: form.input.status,
                      onChange: (function ($$event) {
                          form.updateStatus((function (param, value) {
                                  return {
                                          status: value
                                        };
                                }), $$event.target.value);
                        }),
                      children: [
                        JsxRuntime.jsx(Select.$$Option.make, {
                              value: SuspiciousEmail.statusToString("Pending"),
                              children: "Pending"
                            }, "suspicious-email-form--status-" + SuspiciousEmail.statusToString("Pending")),
                        JsxRuntime.jsx(Select.$$Option.make, {
                              value: SuspiciousEmail.statusToString("Approved"),
                              children: "Approved"
                            }, "suspicious-email-form--status-" + SuspiciousEmail.statusToString("Approved")),
                        JsxRuntime.jsx(Select.$$Option.make, {
                              value: SuspiciousEmail.statusToString("Blocked"),
                              children: "Blocked"
                            }, "suspicious-email-form--status-" + SuspiciousEmail.statusToString("Blocked"))
                      ]
                    }),
                fieldError(form.statusResult),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Button.make, {
                              id: "saveButton",
                              size: "MD",
                              color: "Primary",
                              disabled: form.submitting,
                              busy: form.submitting,
                              submit: true,
                              children: "Save"
                            }),
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "White",
                              busy: false,
                              submit: true,
                              onClick: (function (e) {
                                  e.preventDefault();
                                  Url.visit(Routes_SuspiciousEmail.Dashboard.index);
                                }),
                              children: "Cancel"
                            })
                      ],
                      className: css.buttonContainer
                    })
              ],
              className: css.form,
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  form.submit();
                })
            });
}

var make = SuspiciousEmailEditForm;

export {
  css ,
  initialState ,
  reducer ,
  SuspiciousEmailForm ,
  make ,
}
/* css Not a pure module */
