// extracted by mini-css-extract-plugin
export var column = "SuccessMessage__column__hDpBN";
export var flex = "SuccessMessage__flex__GdG7m";
export var flexColumn = "SuccessMessage__flexColumn__rMw5S";
export var gap1 = "SuccessMessage__gap1__FfuYU";
export var gap2 = "SuccessMessage__gap2__n4mYH";
export var gap3 = "SuccessMessage__gap3__k9nTb";
export var gap4 = "SuccessMessage__gap4__ko0dX";
export var gap5 = "SuccessMessage__gap5__UzVGm";
export var row = "SuccessMessage__row__Z0uok";
export var success = "SuccessMessage__success__YHNsq";