// extracted by mini-css-extract-plugin
export var barContainer = "DashboardSuspiciousEmailNavbar__barContainer__q4ttz";
export var barWrapper = "DashboardSuspiciousEmailNavbar__barWrapper__LBInk";
export var column = "DashboardSuspiciousEmailNavbar__column__hkmo7";
export var flex = "DashboardSuspiciousEmailNavbar__flex__o6Bzm";
export var flexColumn = "DashboardSuspiciousEmailNavbar__flexColumn__A3YSy";
export var gap1 = "DashboardSuspiciousEmailNavbar__gap1__hGmCD";
export var gap2 = "DashboardSuspiciousEmailNavbar__gap2__a0NLl";
export var gap3 = "DashboardSuspiciousEmailNavbar__gap3__XZhCx";
export var gap4 = "DashboardSuspiciousEmailNavbar__gap4__mNLQx";
export var gap5 = "DashboardSuspiciousEmailNavbar__gap5__fHiT8";
export var linkContainer = "DashboardSuspiciousEmailNavbar__linkContainer__ZzirV";
export var linkItem = "DashboardSuspiciousEmailNavbar__linkItem__FtUnd";
export var row = "DashboardSuspiciousEmailNavbar__row__V_Hi8";
export var underlinedLink = "DashboardSuspiciousEmailNavbar__underlinedLink__PVBpe";