// extracted by mini-css-extract-plugin
export var bodyContainer = "SuspiciousEmailEdit__bodyContainer__pU0Lw";
export var column = "SuspiciousEmailEdit__column__ITxIo";
export var container = "SuspiciousEmailEdit__container___sjXA";
export var createdAt = "SuspiciousEmailEdit__createdAt__SVO1r";
export var data = "SuspiciousEmailEdit__data__SeROo";
export var flex = "SuspiciousEmailEdit__flex__SkwNw";
export var flexColumn = "SuspiciousEmailEdit__flexColumn__VOXjZ";
export var gap1 = "SuspiciousEmailEdit__gap1__qMW1v";
export var gap2 = "SuspiciousEmailEdit__gap2__NX4DZ";
export var gap3 = "SuspiciousEmailEdit__gap3__xQadj";
export var gap4 = "SuspiciousEmailEdit__gap4__df3Js";
export var gap5 = "SuspiciousEmailEdit__gap5__ZuSnM";
export var invalid = "SuspiciousEmailEdit__invalid__uayBC";
export var pageContainer = "SuspiciousEmailEdit__pageContainer__gTHCS";
export var row = "SuspiciousEmailEdit__row__Ed5kD";
export var section = "SuspiciousEmailEdit__section__ZsZYE";
export var sectionTitle = "SuspiciousEmailEdit__sectionTitle__CaCEK";
export var title = "SuspiciousEmailEdit__title__K6TD1";
export var titleContainer = "SuspiciousEmailEdit__titleContainer__N_5KO";
export var valid = "SuspiciousEmailEdit__valid__LlJz_";